<template>
  <div id="featurebrowse">
    <MyheadCompnent></MyheadCompnent>
    <FeatureBrowseCompnent2></FeatureBrowseCompnent2>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import FeatureBrowseCompnent2 from '@/components/Browse/FeatureBrowseCompnent2.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'FeatureBrowse2',
    data () {
      return {

      }
    },
    components: {
      FeatureBrowseCompnent2,
      MyheadCompnent,
      MyfooterCompnent,
    },
  }
</script>

<style scoped lang="scss">
</style>