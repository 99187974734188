import common from '@/mymethods/common.js'
export default {
  creatNote: function () {
    var imageUrl = require('@/assets/img/preview.gif')
    var newDiv = document.createElement('div')
    newDiv.id = 'NoteDubbleClick'
    newDiv.className = 'nodaNote'
    var newDiv2 = document.createElement('img')
    newDiv2.width = '1000';
    newDiv2.src = imageUrl
    newDiv2.alt = "preview"
    newDiv.appendChild(newDiv2)
    document.body.appendChild(newDiv);
    console.log("jobover")
  },
  destroyNote: function () {
    var newdiv = document.getElementById("NoteDubbleClick")
    newdiv.remove();
  },
}