<template>
  <div id="featurebrowsecompnent2">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <!-- <div id="speciesselection" style="margin-top: 20px">
      <el-radio-group v-model="species">
        <el-radio-button label="All">All</el-radio-button>
        <el-radio-button label="trae">Wheat</el-radio-button>
        <el-radio-button label="zema">Maize</el-radio-button>
        <el-radio-button label="orsa">Rice</el-radio-button>
        <el-radio-button label="lyes">Tomato</el-radio-button>
      </el-radio-group>
    </div> -->
    <el-container>
      <el-main class="main">
        <h2>Feature filter<el-icon :size="20" color="#e56b07" :onmouseover="this.creatNoteModal"
            :onmouseout="this.destroyNoteModal">
            <QuestionFilled />
          </el-icon>
        </h2>
        <div class="margin1">
          <div class="margin">
            <h3>Q1 (Parent Ion Mass)</h3>
            <el-input v-model="params.searchItem.q1" placeholder="Please input Q1." :clearable="true" />
          </div>
          <!-- <div class="margin">
            <h3>RT</h3>
            <el-input v-model="params.searchItem.rt" placeholder="Please input RT." :clearable="true" />
          </div> -->
          <div class="margin">
            <h3>ESI mode</h3>
            <el-select v-model="params.searchItem.mode" class="m-2" placeholder="Select">
              <el-option key="pos" label="positive" value="positive"></el-option>
              <el-option key="neg" label="negative" value="negative"></el-option>
            </el-select>
          </div>
          <div class="margin">
            <h3>Q1 threshold (ppm)<Tips
                message="<b>'Q1'</b> represents parent ion mass of each feature. The ppm index is calculated using the algorithm below.<br><div><img src='/img/math2.svg' width='400'></div>"
                :width="400">
              </Tips>
            </h3>
            <el-input-number v-model="params.searchItem.ppm" :min="1" :max="100" />
          </div>
          <!-- <div class="margin">
            <h3>delt RT (min)</h3>
            <el-input-number v-model="params.searchItem.drt" :min="0.1" :max="20" />
          </div> -->
          <div class="margin">
            <h3></h3>
            <el-button type="primary" :loading="searchLoading" @click="requestData">Filter
              <Document style="width:12; height:12" />
            </el-button>
          </div>
          <!-- <div class="margin">
            <h3></h3>
            <el-button type="primary" @click="drawMetaboliteHistogram">Draw
              <Document style="width:12; height:12" />
            </el-button>
          </div> -->
        </div>
        <el-table :data="featureData" style="width: 100%" @row-dblclick="detailSearch" max-height="800">
          <el-table-column label="Feature ID" width="180" :sortable="true" sort-by="featureID">
            <template #default="scope">
              <!-- <p>{{ scope.row.featureID }}</p> -->
              <a :href="$store.state.frontbaseURL+'featuredetail2/'+scope.row.featureID">{{ scope.row.featureID
                }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="species" width="140" label="Species" :sortable="true" sort-by="species">
            <template #default="scope">
              <div>{{ this.$store.state.speciesDict[scope.row.species] }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="rt" width="140" label="RT" :sortable="true"></el-table-column>
          <el-table-column prop="q1" width="140" label="Q1" :sortable="true"></el-table-column>
          <el-table-column prop="mode" width="120" label="Mode" :sortable="true"></el-table-column>
          <el-table-column prop="metabolite" label="Annotation" width="300" :sortable="true">
          </el-table-column>
          <el-table-column prop="alignScore" width="160" :sortable="true">
            <template #header>
              <div>Annotation</div>
              <div>score</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Annotation name" width="200" :sortable="true"></el-table-column>
          <el-table-column>
            <template #header>
              selection
              <el-checkbox v-model="selectionAll" label="" size="large" />
              <div>
                <el-button type="primary" @click="drawMetaboliteHistogram" style="min-height: 4px;padding: 1px 20px">
                  Draw
                </el-button>
              </div>

            </template>
            <template #default="scope" width="200">
              <!-- {{ scope.row.selection}} -->
              <el-checkbox v-model="scope.row.selection" label="" size="large" />

            </template>
          </el-table-column>
        </el-table>
        <h2>Feature content</h2>
        <div id="metaboliteHistogram"></div>
      </el-main>
    </el-container>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import { ElLoading } from 'element-plus'
  import request from '@/network/request'
  import $ from 'jquery'
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import common from '@/mymethods/common.js'
  import modal from '@/mymethods/modal.js'
  import { QuestionFilled } from '@element-plus/icons-vue'
  import modalNote from '@/mymethods/modalNote.js'
  import Tips from '@/components/Common/Tips.vue'
  let canvas
  let context1
  export default {
    name: 'FeatureBrowseCompnent2',
    data () {
      return {
        species: 'All',
        searchItem: {
          page: 1,
          size: 10,
        },
        paginationArg: {

        },
        params: {
          searchType: 'filter',
          searchItem: {
            q1: 331.0811,
            rt: 8,
            drt: 20,
            ppm: 10,
            mode: "positive",
          },
        },
        featureData: "",
        featureMs: "",
        head: "Requesting !",
        searchLoading: false,
        selectionAll: true,
        startLeft: 0,
        startTop: 0
      }
    },
    methods: {
      requestData () {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        request({
          url: 'feature2/list/',
          params: this.params,
        }).then(res => {
          for (let i = 0; i < res.Feature2Info.length; i++) {
            if (res.Feature2Info[i]["alignScore"]) {
              res.Feature2Info[i]["alignScore"] = Number(res.Feature2Info[i]["alignScore"].toFixed(2))
            } else {
              res.Feature2Info[i]["metabolite"] = ""
              res.Feature2Info[i]["name"] = ""
            }

            res.Feature2Info[i]["rt"] = Number(res.Feature2Info[i]["rt"].toFixed(1))
            if (i < 20) {
              res.Feature2Info[i]["selection"] = true
            } else {
              res.Feature2Info[i]["selection"] = false
            }
          }
          this.featureData = res.Feature2Info
          this.featureMs = res.Feature2Ms
          this.head = "Feature Analysis"
          loading.close()
          this.drawMetaboliteHistogram()
        }).catch(err => {
          console.log(err);
        });
      },
      requestDataParams (params) {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        request({
          url: 'feature2/list/',
          params: params,
        }).then(res => {
          for (let i = 0; i < res.Feature2Info.length; i++) {
            if (res.Feature2Info[i]["alignScore"]) {
              res.Feature2Info[i]["alignScore"] = Number(res.Feature2Info[i]["alignScore"].toFixed(2))
            } else {
              res.Feature2Info[i]["metabolite"] = ""
              res.Feature2Info[i]["name"] = ""
            }

            res.Feature2Info[i]["rt"] = Number(res.Feature2Info[i]["rt"].toFixed(1))
            if (i < 20) {
              res.Feature2Info[i]["selection"] = true
            } else {
              res.Feature2Info[i]["selection"] = false
            }

          }
          this.featureData = res.Feature2Info
          this.featureMs = res.Feature2Ms
          this.head = "Feature Analysis"
          loading.close()
          this.drawMetaboliteHistogram()
        }).catch(err => {
          console.log(err);
        });
      },
      requestData2 () {
        request({
          url: 'feature2/list/',
          params: this.params,
        }).then(res => {
          for (let i = 0; i < res.Feature2Info.length; i++) {
            res.Feature2Info[i]["selection"] = true
          }
          this.featureData = res.Feature2Info
          this.featureMs = res.Feature2Ms
          this.drawMetaboliteHistogram()
        }).catch(err => {
          console.log(err);
        });
      },
      drawMetaboliteHistogram () {
        var selectID = new Set()
        for (let i of this.featureData) {
          if (i.selection) {
            selectID.add(i.featureID)
          }
        }

        var metaboliteList = []
        for (let i of this.featureMs) {

          if (selectID.has(i.featureID)) {

            metaboliteList.push(i)
          }
        }

        common.drawHistogram(metaboliteList)
      },
      drawLibraryData (dataDict, id) {
        var ms2 = []
        var Q1 = dataDict.q1
        for (let i in dataDict) {
          ms2.push(dataDict[i])
        }
        ms2 = ms2.splice(4)
        var ms2plot = []
        for (let i in ms2) {
          if (ms2[i]) {
            ms2plot.push(ms2[i])
          }
        }



        common.drawMsData2(ms2plot, id, {
          Q1: Q1,
          transformX: 5,
          transformY: 15,
          width: 500,
          height: 200,
          xlab: "16px",
          ylab: "16px"
        })
      },
      detailSearch (row, column, event) {
        var elementT = document.getElementById(row.featureID);
        var elementExists = elementT !== null
        // 输出结果
        if (elementExists) {
          elementT.style.top = '50%';
          elementT.style.left = '50%';

        } else {
          var searchItems = {
            "format": "json",
            "searchItem": {
              "featureSelect": "Feature2",
              "featureID": row.featureID
            }
          }
          request({
            url: 'feature/indivadualDetail/',
            params: searchItems,
          }).then(res => {
            var FeatureMsTemp = res.FeatureMs[0]
            var FeatureInfoTemp = res.FeatureInfo[0]


            modal.creatModal(FeatureMsTemp, row.featureID)
            // var newDiv = document.createElement('div')
            // newDiv.id = row.featureID
            // newDiv.className = 'new-div'
            // newDiv.draggable = 'true';
            // newDiv.addEventListener('dragstart', this.dragStart)
            // newDiv.addEventListener('dragend', this.dragEnd)
            // var newDiv2 = document.createElement('div')
            // newDiv2.innerText = row.featureID;
            // newDiv2.align = 'center';
            // newDiv2.className = 'new-div2'
            // var newButton = document.createElement('i');
            // newButton.className = "el-icon-close"
            // newButton.style.float = "right"
            // newButton.addEventListener('click', this.closeFunction)
            // newDiv2.appendChild(newButton)
            // newDiv.appendChild(newDiv2)
            // document.body.appendChild(newDiv);
            // this.drawLibraryData(FeatureMsTemp, "#" + row.featureID)
          }).catch(err => {
            console.log(err);
          });
        }
      },
      dragStart (e) {
        this.startLeft = e.x
        this.startTop = e.y
      },
      ondrag (e) {
      },
      dragEnd (e) {
        var startLeft = e.target.offsetLeft
        var startTop = e.target.offsetTop
        var elem = document.getElementById(e.target.id);
        elem.style.left = startLeft + e.x - this.startLeft + "px";
        elem.style.top = startTop + e.y - this.startTop + "px";
      },
      closeFunction (e) {
        var parentElement = e.target.parentElement.parentElement
        if (parentElement) {
          parentElement.remove();
        }
      },
      creatNoteModal () {
        modalNote.creatNote()

      },
      destroyNoteModal () {
        modalNote.destroyNote()
      },
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      QuestionFilled,
      Tips,
    },
    created () {
      if (this.$route.params.selectItem) {
        var params = {
          searchType: 'item',
          searchItem: this.$route.params
        }
        this.requestDataParams(params)
      } else {
        this.requestData()
      }
    },
    mounted () {

    },
    updated () {

    },
    // 检测params.searchItem的编号
    watch: {
      selectionAll: {
        handler (val, olVal) {
          if (val) {
            for (let i of this.featureData) {
              i["selection"] = true
            }
          } else {
            for (let i of this.featureData) {
              i["selection"] = false
            }
          }
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    /* background-color: #e9eef3; */
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  h2 {
    text-align: left;
  }

  .main {
    margin-right: 100px;
    margin-left: 100px;
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
    font-size: 20px;
  }

  .el-table .cell {
    word-break: break-word;
    color: rgb(247, 4, 4);
  }

  .margin1 {
    display: flex;
    align-content: space-around;
    margin-bottom: 20px;
  }

  .margin {
    margin-right: 40px;
  }

  #metaboliteHistogram {
    margin-top: 40px;
  }

  #speciesselection {
    position: relative;
    top: 0px;
    left: 20px;
    margin-bottom: 10px;
  }
</style>