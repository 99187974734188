import common from '@/mymethods/common.js'
export default {
  creatModal: function (FeatureMsTemp, featureID) {
    var start = {
      "startLeft": "",
      "startTop": ""
    }
    var dragStart = function (e) {
      start.startLeft = e.x
      start.startTop = e.y
    }
    var dragEnd = function (e) {
      var startLeft = e.target.offsetLeft
      var startTop = e.target.offsetTop
      var elem = document.getElementById(e.target.id);
      elem.style.left = startLeft + e.x - start.startLeft + "px";
      elem.style.top = startTop + e.y - start.startTop + "px";
    }
    var newDiv = document.createElement('div')
    newDiv.id = featureID
    newDiv.className = 'new-div'
    newDiv.draggable = 'true';
    newDiv.addEventListener('dragstart', dragStart)
    newDiv.addEventListener('dragend', dragEnd)
    var newDiv2 = document.createElement('div')
    newDiv2.innerText = featureID;
    newDiv2.align = 'center';
    newDiv2.className = 'new-div2'
    var newButton = document.createElement('i');
    newButton.className = "el-icon-close"
    newButton.style.float = "right"
    var closeFunction = function (e) {
      var parentElement = e.target.parentElement.parentElement
      if (parentElement) {
        parentElement.remove();
      }
    }
    newButton.addEventListener('click', closeFunction)
    newDiv2.appendChild(newButton)
    newDiv.appendChild(newDiv2)
    document.body.appendChild(newDiv);
    var drawLibraryData = function (dataDict, id) {
      var ms2 = []
      var Q1 = dataDict.q1
      var spliceNum = 4
      for (let i in dataDict) {
        ms2.push(dataDict[i])
      }
      ms2 = ms2.splice(spliceNum)
      var ms2plot = []
      for (let i in ms2) {
        if (ms2[i]) {
          ms2plot.push(ms2[i])
        }
      }
      common.drawMsData2(ms2plot, id, {
        Q1: Q1,
        transformX: 5,
        transformY: 15,
        width: 800,
        height: 300,
        xlab: "16px",
        ylab: "16px"
      })
    }

    drawLibraryData(FeatureMsTemp, "#" + featureID)
  }
}