<template>
  <el-popover :width="width" trigger="hover"
    popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;">
    <template #reference>
      <el-icon :size="20" color="#6c88b2">
        <QuestionFilled />
      </el-icon>
      <!-- <el-avatar src="https://avatars.githubusercontent.com/u/72015883?v=4" /> -->
      <!-- <div>job</div> -->
    </template>
    <template #default>
      <div v-html="message" style="overflow-wrap: break-word; word-break: normal;font-size: 18px"></div>
    </template>
  </el-popover>
</template>

<script>
  import { QuestionFilled } from '@element-plus/icons-vue'
  export default {
    name: 'MyheadCompnent',
    data () {
      return {
        show: true,
        username: '',
      }
    },

    methods: {

    },
    created () {

    },
    components: {
      QuestionFilled,
    },
    props: {
      message: {
        type: String,
        default: '',
      },
      width: {
        type: Number,
        default: 400
      }
    },
  }
</script>

<style scoped lang="scss">
  .common-layout {
    margin-bottom: 20px;
  }

  .el-header {
    background-color: #ffffff;
    color: #333;
    text-align: left;
    height: 60px;
    padding: 0px;
  }

  .el-row {
    height: 80px;
    /* background-color: #fff0e2; */
    background-color: rgb(255, 134, 23, 0.6);

    padding: 0px;
  }

  .el-col {
    margin-top: 20px;
  }

  /* .column-span1 {
    display: table;
    cursor: pointer;
  }
  .column-div {
    display: table-cell;
    vertical-align: middle;
    border-collapse: separate;
    border-spacing: 40px 5px;
  } */
  .column-span1 {
    text-align: center;
    cursor: pointer;
  }

  .column-div {
    display: inline-block;
    vertical-align: middle;
    margin-left: 40px;
    text-align: left;
  }

  .home-figure {
    cursor: pointer;
    width: 60px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }



  .column-div li {
    list-style-type: none;
    margin: 10px 4px 4px 4px;
  }


  .dropdown-content {
    cursor: pointer;
    display: none;
    position: absolute;
    background-color: #ffcc00;
    min-width: 80px;
    z-index: 999
  }

  .column-div:hover .dropdown-content {
    display: block;
  }

  .el-main {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    max-height: 1000px;
  }

  .router-link-active {
    color: #e56b07
  }

  .router-link-exact-active {
    color: #e56b07
  }



  .user {
    color: #000000
  }

  .user:hover {
    color: #e56b07
  }

  a {
    text-decoration: none;
  }
</style>